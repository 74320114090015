import React from 'react';

import Layout from '../../../components/Layout/Auth';
import Content from '../../../components/Pages/Auth/VerificationSuccess';
import { displayName } from '../../../config';

const VerificationSuccess = (): JSX.Element => {
  document.title = `${displayName}: Verification Success`;

  return (
    <Layout withHeader>
      <Content />
    </Layout>
  );
};

export default VerificationSuccess;
