import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Button, Typography } from 'antd';
import React from 'react';

import { charactersLimit } from '../../../../../config';

const CreateCharacterLimits: React.FC = () => (
  <div className="flex-column flex-align-center gap-16">
    <SentimentVeryDissatisfiedIcon className="fs-80 color-primary" />
    <Typography.Title className="fs-26 color-primary">The limits are reached!</Typography.Title>
    <div className="flex-column flex-align-center">
      <Typography className="fs-16 color-primary">
        Sorry, you can only have {charactersLimit} character in the
      </Typography>
      <Typography className="fs-16 color-primary">free version</Typography>
    </div>
    <Button type="primary" href="/payment">
      View tariffs
    </Button>
  </div>
);

export default CreateCharacterLimits;
