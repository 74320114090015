import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';

import { alertDelayError, alertDelayResendEmail } from '../../../../config';
import { Action } from '../../../../store';
import {
  moduleName as authModuleName,
  sendVerificationCodeAction,
  verifyByCodeAction,
} from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { IUser } from '../../../../types/entries';
import styles from '../index.module.less';

interface IVerifyEmail {
  loading: boolean;
  user: IUser | null;
  error: Error | null;
  verifyEmail: (code: string) => Action;
  sendVerificationCode: () => Action;
}

const codeInputStyles: React.CSSProperties = {
  width: '3rem',
  height: '3rem',
  borderRadius: '0.25rem',
  border: '1px solid var(--color-white)',
  background: 'transparent',
  color: 'var(--color-white)',
  fontSize: '1rem',
  outline: 'none',
  textAlign: 'center',
};

const VerifyEmail: React.FC<IVerifyEmail> = ({ loading, user, error, verifyEmail, sendVerificationCode }) => {
  let interval: string | number | NodeJS.Timeout | undefined;
  const [code, setCode] = useState<string>('');
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(0);

  useEffect(() => {
    if (!error) return;

    setIsCodeValid(false);
    setTimeout(() => {
      setIsCodeValid(true);
    }, alertDelayError);
  }, [error]);

  const handleCodeChange = (value: string) => {
    if (!isCodeValid) {
      setIsCodeValid(true);
    }
    setCode(value);
  };

  const handleVerifyEmail = () => {
    verifyEmail(code);
  };

  const handleResendCode = () => {
    if (timer) return;

    sendVerificationCode();
    setTimer(alertDelayResendEmail);

    interval = setInterval(() => {
      setTimer((prev) => {
        const nextValue = prev - 1;

        if (nextValue <= 0) {
          clearInterval(interval);

          return 0;
        }

        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => clearInterval(interval), []);

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <MailOutlinedIcon className={styles.icon} />
        <Typography.Title className={styles.title}>Sign Up to Neural Voice</Typography.Title>
      </div>
      <div>
        <Typography.Text className={styles.description}>We sent a verification link to</Typography.Text>
        <Typography.Text className={styles.description}>{user?.email}</Typography.Text>
      </div>
      <div className="flex-column flex-align-center gap-4">
        <Typography.Text className={clsx(styles.description, 'p-0')}>Don't have a code?</Typography.Text>
        {timer ? (
          <Typography.Text className="color-primary fs-18">
            0{Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
          </Typography.Text>
        ) : (
          <Link to="" type="text" className="color-blue p-0" onClick={handleResendCode}>
            Send again
          </Link>
        )}
      </div>

      <div className={styles.codeInputWrapper}>
        <ReactCodeInput
          className={styles.codeInputRow}
          type="number"
          fields={4}
          inputMode="numeric"
          name="confirmationCode"
          isValid={isCodeValid}
          inputStyleInvalid={{
            ...codeInputStyles,
            animation: 'shake 0.2s ease-in-out 0s 2',
            border: '1px solid var(--color-brand-red)',
          }}
          inputStyle={codeInputStyles}
          onChange={handleCodeChange}
        />
        <Typography className={styles.error} style={{ margin: 0, opacity: isCodeValid ? 0 : 1 }}>
          Code is invalid
        </Typography>
      </div>

      <Button type="primary" size="large" onClick={handleVerifyEmail} className={styles.authButton} loading={loading}>
        Verify Email
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  user: state[authModuleName].user,
  error: state[authModuleName].error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  verifyEmail: (code: string) => dispatch(verifyByCodeAction(code)),
  sendVerificationCode: () => dispatch(sendVerificationCodeAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
