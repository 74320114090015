import React from 'react';

import Content from '../../components/Pages/Plans';
import { displayName } from '../../config';
import Layout from '../../components/Layout/Main';

const Payment: React.FC = () => {
  document.title = `${displayName}: Payment`;

  return (
    <Layout title="Payment">
      <Content />
    </Layout>
  );
};

export default Payment;
