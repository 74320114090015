import { Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

import { IMessage } from '../../../../../../types/entries';
import styles from '../../index.module.less';

interface ITranscriptTab {
  messages?: IMessage[];
}

const TranscriptTab: React.FC<ITranscriptTab> = ({ messages }) =>
  messages ? (
    <div className={styles.messagesBox}>
      {messages.map(({ id, role, content }) => (
        <p key={id} className={clsx(styles.message, { [styles.sent]: role === 'user' })}>
          {content}
        </p>
      ))}
    </div>
  ) : (
    <div className={styles.placeholder}>
      <Typography className="color-primary">No transcript yet</Typography>
    </div>
  );

TranscriptTab.defaultProps = {
  messages: undefined,
};

export default TranscriptTab;
