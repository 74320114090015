import { Add, Close } from '@mui/icons-material';
import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../store';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import {
  IAddDomain,
  IDeleteDomain,
  IGetDomains,
  addDomainAction,
  moduleName as customerModuleName,
  deleteDomainAction,
  getDomainsAction,
} from '../../../../store/ducks/customer';
import { RootState } from '../../../../store/reducers';
import { IDomain } from '../../../../types/customer';
import { IUser } from '../../../../types/entries';
import ContentCard from '../../../Common/ContentCard';
import Loading from '../../../Common/Loading';
import styles from '../index.module.less';

interface IDomainSetup {
  user: IUser | null;
  loading: boolean;
  domains: IDomain[] | null;
  getDomains: (params: IGetDomains) => Action;
  addDomain: (params: IAddDomain) => Action;
  deleteDomain: (params: IDeleteDomain) => Action;
}

const DomainSetup: React.FC<IDomainSetup> = ({ user, loading, domains, getDomains, addDomain, deleteDomain }) => {
  const [form] = useForm();
  const organisation_uuid = useMemo(() => user?.organisation?.uuid || '', [user]);

  useEffect(() => {
    getDomains({ organisation_uuid });
  }, [organisation_uuid]);

  const handleAddDomain = () => {
    form.validateFields().then(({ domain }) => {
      if (!domain || !user?.organisation?.uuid) {
        return;
      }

      addDomain({ organisation_uuid, domain });
      form.resetFields();
    });
  };

  const handleDeleteDomain = (domain_uuid: string) => {
    deleteDomain({ organisation_uuid, domain_uuid });
  };

  return (
    <ContentCard>
      <Loading visible={loading} absolute className="background-transparent backdrop-filter-blur-10 border-radius-16" />
      <div className="flex-column gap-16">
        <Typography.Title level={3} className="color-primary fs-20 m-0">
          Verify your domain
        </Typography.Title>
        <Form form={form} layout="vertical">
          <Form.Item name="domain" noStyle>
            <Input
              style={{ paddingRight: 0 }}
              suffix={
                <Button type="text" onClick={handleAddDomain} className={clsx(styles.iconButton, styles.addButton)}>
                  <Add />
                </Button>
              }
            />
          </Form.Item>
        </Form>
        {domains && !!domains.length && (
          <>
            <Typography.Title level={3} className="color-primary fs-20 m-0">
              Registered domains
            </Typography.Title>
            <div className="flex-column gap-8">
              {domains.map((item) => (
                <div key={item.uuid} className={styles.domainRow}>
                  <Typography className="color-primary fs-16">{item.domain}</Typography>
                  <Button
                    type="text"
                    onClick={() => handleDeleteDomain(item.uuid)}
                    className={clsx(styles.iconButton, styles.deleteButton)}
                  >
                    <Close />
                  </Button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </ContentCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[customerModuleName].domainsLoading,
  domains: state[customerModuleName].domains,
  user: state[authModuleName].user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDomains: (params: IGetDomains) => dispatch(getDomainsAction(params)),
  addDomain: (params: IAddDomain) => dispatch(addDomainAction(params)),
  deleteDomain: (params: IDeleteDomain) => dispatch(deleteDomainAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DomainSetup);
