import React from 'react';

import Layout from '../../../../components/Layout/Main';
import Content from '../../../../components/Pages/Customer/Characters/CreateCharacter';
import { displayName } from '../../../../config';

const CreateCharacter = (): JSX.Element => {
  document.title = `${displayName}: Create Character`;

  return (
    <Layout title="Create your first character">
      <Content />
    </Layout>
  );
};

export default CreateCharacter;
