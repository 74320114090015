import { Typography } from 'antd';
import React from 'react';

interface ISummaryTab {
  summary?: string;
}

const SummaryTab: React.FC<ISummaryTab> = ({ summary }) => (
  <div className="h-full">
    {summary ? (
      <Typography className="fs-12 color-primary lh-16">{summary}</Typography>
    ) : (
      <div className="flex-column flex-align-center flex-justify-center">
        <Typography className="color-primary">No summary yet</Typography>
      </div>
    )}
  </div>
);

SummaryTab.defaultProps = {
  summary: undefined,
};

export default SummaryTab;
