import { InboxOutlined } from '@ant-design/icons';
import { Button, Typography, UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React, { useState } from 'react';

import UploadModal from '../UploadModal';

interface ITextUpload {
  name: string;
}
const FileUpload: React.FC<ITextUpload> = ({ name }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleModalOpen = () => {
    setIsVisible(true);
  };

  const handleModalClose = () => {
    setIsVisible(false);
  };

  const handleModalOk = () => {
    // eslint-disable-next-line no-console
    console.log('file');

    handleModalClose();
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
  };

  return (
    <>
      <UploadModal
        title={`Upload information for ${name}`}
        isVisible={isVisible}
        onOk={handleModalOk}
        onClose={handleModalClose}
      >
        <div className="flex-column gap-10">
          <div className="flex-column gap-5">
            <Typography className="fs-16 lh-1 color-primary fw-500">Upload files</Typography>
            <Typography className="fs-16 lh-1 color-primary fw-400">
              Upload documents, spreadsheets, presentations and more for {name} understand
            </Typography>
          </div>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned
              files.
            </p>
          </Dragger>
        </div>
      </UploadModal>
      <Button type="default" onClick={handleModalOpen}>
        Upload File
      </Button>
    </>
  );
};

export default FileUpload;
