import React from 'react';

import Layout from '../../../components/Layout/Auth';
import Content from '../../../components/Pages/Auth/VerifyEmail';
import { displayName } from '../../../config';

const VerifyEmail = (): JSX.Element => {
  document.title = `${displayName}: Verify Email`;

  return (
    <Layout withHeader>
      <Content />
    </Layout>
  );
};

export default VerifyEmail;
