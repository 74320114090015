import React from 'react';

import Layout from '../../components/Layout/Main';
import Content from '../../components/Pages/AccountSettings';
import { displayName } from '../../config';

const AccountSettings = (): JSX.Element => {
  document.title = `${displayName}: Account Settings`;

  return (
    <Layout title="Account Settings" withBackButton>
      <Content />
    </Layout>
  );
};

export default AccountSettings;
