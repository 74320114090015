import { DarkMode, LightMode, MenuOpenOutlined, SettingsOutlined } from '@mui/icons-material';
import { Button, Typography } from 'antd';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useThemeDetector } from '../../../../hooks/theme';
import sideMenuRoutes from '../../../../routes/sideMenu';
import { moduleName as authModuleName } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { IUser, UserRoleEnum } from '../../../../types/entries';
import { LogoSmall } from '../../../Common/Icon';
import styles from '../index.module.less';

interface IMenuCommon {
  user: IUser | null;
}
const MenuCommon: React.FC<IMenuCommon> = ({ user }) => {
  const [isDark, toggleTheme] = useThemeDetector();

  const { pathname } = useLocation();

  const [key, setKey] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setKey(pathname.split('/').find((item) => !!item) || '');
  }, [pathname]);

  const handleToggleExpanded = () => {
    setIsExpanded((prev) => !prev);
  };

  const filteredRoutes = sideMenuRoutes.filter((route) => !!route.adminRoute === (user?.role === UserRoleEnum.admin));

  return (
    <>
      <div className={clsx(styles.substrate, { [styles.expanded]: isExpanded })} onClick={handleToggleExpanded} />
      <div className={clsx(styles.sidebarContainer, { [styles.expanded]: isExpanded })}>
        <Link className={clsx(styles.menuLink, styles.logoLink)} to="/">
          <div>
            <LogoSmall className={styles.logo} />
          </div>
          <Typography.Title level={3} className={styles.menuLinkText}>
            Neural Voice
          </Typography.Title>
        </Link>
        <nav className={clsx(styles.menuContainer)}>
          <ol className={styles.menuList}>
            {filteredRoutes.map((route) => (
              <li
                className={clsx(styles.menuItem, { [styles.active]: route.bind.path === `/${key}` })}
                key={route.name}
              >
                <Link className={styles.menuLink} to={route.bind.path || '/'}>
                  <Typography className={styles.menuLinkIcon}>{route.icon}</Typography>
                  <Typography className={styles.menuLinkText}>{route.name}</Typography>
                </Link>
              </li>
            ))}
          </ol>
          <div className={clsx(styles.menuItem, { [styles.active]: `/${key}` === '/settings' })}>
            <Link className={styles.menuLink} to="/settings">
              <Typography className={styles.menuLinkIcon}>
                <SettingsOutlined />
              </Typography>
              <Typography className={styles.menuLinkText}>Settings</Typography>
            </Link>
          </div>
          <div className={styles.menuSection}>
            <div className={styles.menuItem}>
              <Button className={styles.menuLink} type="text" onClick={toggleTheme}>
                <Typography className={styles.menuLinkIcon}>{isDark ? <LightMode /> : <DarkMode />}</Typography>
                <Typography className={styles.menuLinkText}>Toggle theme</Typography>
              </Button>
            </div>
          </div>
          <div className={styles.menuSection}>
            <Button
              type="text"
              onClick={handleToggleExpanded}
              className={clsx(styles.expandButton, { [styles.expanded]: isExpanded })}
            >
              <Typography className={styles.menuLinkIcon}>
                <MenuOpenOutlined />
              </Typography>
            </Button>
          </div>
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(MenuCommon);
