import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../../store';
import {
  IGetRecord,
  getRecord as getRecordAction,
  moduleName as recordsModuleName,
} from '../../../../../store/ducks/records';
import { RootState } from '../../../../../store/reducers';
import { IRecord } from '../../../../../types/entries';
import ContentCard from '../../../../Common/ContentCard';
import PageWrapper from '../../../../Common/PageWrapper';
import CollectedDataTab from './CollectedDataTab';
import NotesTab from './NotesTab';
import RecordInfo from './RecordInfo';
import SummaryTab from './SummaryTab';
import TranscriptTab from './TranscriptTab';

interface ICharacterDetails {
  loading: boolean;
  record: IRecord | null | undefined;
  getRecord: (payload: IGetRecord) => Action;
}
const RecordDetails: React.FC<ICharacterDetails> = ({ loading, record, getRecord }) => {
  const navigate = useNavigate();

  const { characterId, recordId } = useParams();

  useEffect(() => {
    if (!recordId || !characterId) return;

    getRecord({ id: recordId, characterId });
  }, [recordId]);

  useEffect(() => {
    if (record === null) {
      navigate(-1);
    }
  }, [record]);

  return (
    <PageWrapper
      characterId={characterId}
      loading={loading}
      extraColumn={{
        width: 180,
        align: 'center',
        dataIndex: 'id',
        renderText: (id) => (
          <Link to={`/characters/${id}/records`} className="text-decoration-none">
            All Calls
          </Link>
        ),
      }}
    >
      <ContentCard title="Call Details" width="100%">
        <RecordInfo record={record} />
      </ContentCard>
      <div className="grid grid-cols-2 gap-16" style={{ gridTemplateColumns: '1fr 2fr' }}>
        <ContentCard height="100%">
          <Tabs
            defaultActiveKey="Collected Data"
            type="card"
            style={{ height: '100%' }}
            items={[
              {
                label: 'Collected Data',
                key: 'Collected Data',
                children: <CollectedDataTab collectedData={record?.customerData} />,
              },
              {
                label: 'Summary',
                key: 'Summary',
                children: <SummaryTab summary={record?.summary} />,
              },
            ]}
          />
        </ContentCard>
        <ContentCard height="100%">
          <Tabs
            defaultActiveKey="Transcript"
            type="card"
            style={{ height: '100%' }}
            items={[
              {
                label: 'Transcript',
                key: 'Transcript',
                children: <TranscriptTab messages={record?.messages} />,
              },
              {
                label: 'Notes',
                key: 'Notes',
                children: <NotesTab notes={record?.notes} />,
              },
            ]}
          />
        </ContentCard>
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[recordsModuleName].loading,
  record: state[recordsModuleName].record,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRecord: (payload: IGetRecord) => dispatch(getRecordAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RecordDetails);
