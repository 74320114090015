import { IPlan } from '../types';
import { IVoice } from '../types/entries';

export const CUSTOMER_ID = process.env.REACT_APP_CUSTOMER_ID;
export const CLIENT_EMAILS = process.env.REACT_APP_CLIENT_EMAIL?.split(',') || [];
export const CLIENT_PASSWORDS = process.env.REACT_APP_CLIENT_PASSWORD?.split(',') || [];

export const CLIENT_FIRST_NAME_ARRAY = process.env.REACT_APP_CLIENT_FIRST_NAME?.split(',') || [];
export const CLIENT_LAST_NAME_ARRAY = process.env.REACT_APP_CLIENT_LAST_NAME?.split(',') || [];

export const PLANS: IPlan[] = [
  {
    id: '8d2dccd5-58b0-4a39-b50f-412a21de6d89',
    name: 'Lite',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 50,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '200 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web integration',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: 'cda5e9a0-4858-4c74-b942-1affee0871a7',
    name: 'Standard',
    description: 'Basic AI voice features ideal for startup web and phone use',
    price: 400,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '1,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: false,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: false,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
  },
  {
    id: '8255dd54-314b-400a-ba9f-6ce00e5ea2ed',
    name: 'Professional',
    description: 'Expanded AI integration and features for involving business needs',
    price: 1650,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '5,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: false,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: false,
      },
    ],
    isActive: true,
  },
  {
    id: '404a959d-c184-4d52-8023-8960ab83b095',
    name: 'Enterprise',
    description: 'Comprehensive AI solution with full support for large enterprises',
    price: 3200,
    currency: 'gbp',
    period: 'month',
    features: [
      {
        label: '10,000 Neural Voice minutes',
        isEnabled: true,
      },
      {
        label: 'Neural Knowledge base',
        isEnabled: true,
      },
      {
        label: 'Neural Voice management dashboard',
        isEnabled: true,
      },
      {
        label: 'Web and Phone',
        isEnabled: true,
      },
      {
        label: 'CRM integration',
        isEnabled: true,
      },
      {
        label: 'Outbound Phone Calls',
        isEnabled: true,
      },
      {
        label: 'Automated workflows and triggers',
        isEnabled: true,
      },
      {
        label: '2-way CRM Data Injection',
        isEnabled: true,
      },
    ],
  },
];

export const VOICES_LIST: IVoice[] = [
  {
    id: 'EXAVITQu4vr4xnSDxMaL',
    gender: 'Female',
    age: 'Middle Aged',
    accent: 'American',
  },
  {
    id: 'rCmVtv8cYU60uhlsOo1M',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'ZF6FPAbjXT4488VcRRnw',
    gender: 'Female',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'onwK4e9ZLuTAKqWW03F9',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'American',
  },
  {
    id: 'kmSVBPu7loj4ayNinwWM',
    gender: 'Male',
    age: 'Young Adult',
    accent: 'British',
  },
  {
    id: 'Tx7VLgfksXHVnoY6jDGU',
    gender: 'Male',
    age: 'Middle Aged',
    accent: 'British',
  },
];
