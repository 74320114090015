import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { Typography } from 'antd';
import React from 'react';

const CreateCharacterSuccess: React.FC = () => (
  <div className="flex-column flex-align-center gap-16 pv-40">
    <CloudDoneIcon className="fs-80 color-green" />
    <Typography.Title className="fs-40 color-primary">Done !</Typography.Title>
    <Typography className="fs-16 color-primary">New character successfully created</Typography>
  </div>
);

export default CreateCharacterSuccess;
