import { Typography } from 'antd';
import React from 'react';

import styles from '../../index.module.less';

interface INotesTab {
  notes?: string;
}

const NotesTab: React.FC<INotesTab> = ({ notes }) => (
  <div className={styles.textContainer}>
    {notes ? (
      <Typography className={styles.text}>{notes}</Typography>
    ) : (
      <div className="flex-column flex-align-center flex-justify-center">
        <Typography className="color-primary">No notes yet</Typography>
      </div>
    )}
  </div>
);

NotesTab.defaultProps = {
  notes: undefined,
};

export default NotesTab;
