import { Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../../../store';
import { moduleName as authModuleName } from '../../../../../store/ducks/auth';
import {
  ICheckIfCharacterCanBeCreatedAction,
  ICreateCharacterParams,
  moduleName as charactersModuleName,
  checkIfCharacterCanBeCreatedAction,
  createCharacterAction,
} from '../../../../../store/ducks/characters';
import { RootState } from '../../../../../store/reducers';
import { IUser } from '../../../../../types/entries';
import Loading from '../../../../Common/Loading';
import CreateCharacterForm from '../CreateCharacterForm';

interface ICharacters {
  user: IUser | null;
  loading: boolean;
  characterLoading: boolean;
  isCharacterCanBeCreated: boolean;
  createCharacter: (params: ICreateCharacterParams) => Action;
  checkIfCharacterCanBeCreated: (params: ICheckIfCharacterCanBeCreatedAction) => Action;
}

const CreateCharacter: React.FC<ICharacters> = ({
  user,
  loading,
  characterLoading,
  isCharacterCanBeCreated,
  createCharacter,
  checkIfCharacterCanBeCreated,
}) => {
  const [form] = useForm();
  const organisation_uuid = useMemo(() => user?.organisation?.uuid || '', [user]);

  const navigate = useNavigate();

  const characterCreateCallback = () => {
    navigate('/characters');
  };

  useEffect(() => {
    checkIfCharacterCanBeCreated({ organisation_uuid });
  }, []);

  useEffect(() => {
    if (!isCharacterCanBeCreated) {
      navigate('/characters');
    }
  }, [isCharacterCanBeCreated]);

  const handleCharacterSave = () => {
    form
      .validateFields()
      .then((values: ICreateCharacterParams) => {
        createCharacter({ ...values, callback: characterCreateCallback });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Validation Failed:', error);
      });
  };

  return (
    <div className="flex-column gap-40 flex-align-center flex-justify-center">
      <Loading
        visible={loading || characterLoading}
        fixed
        className="background-transparent backdrop-filter-blur-10 border-radius-16"
      />

      <div style={{ width: '100%', maxWidth: '30rem', margin: 'auto' }}>
        <CreateCharacterForm form={form} />
        <div className="flex-row flex-justify-end">
          <Button type="primary" onClick={handleCharacterSave} loading={loading}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
  loading: state[charactersModuleName].loading,
  characterLoading: state[charactersModuleName].characterLoading,
  isCharacterCanBeCreated: state[charactersModuleName].isCharacterCanBeCreated,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  createCharacter: (params: ICreateCharacterParams) => dispatch(createCharacterAction(params)),
  checkIfCharacterCanBeCreated: (params: ICheckIfCharacterCanBeCreatedAction) =>
    dispatch(checkIfCharacterCanBeCreatedAction(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCharacter);
