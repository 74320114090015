import React from 'react';

import Content from '../../../components/Pages/Plans/Confirmation';
import { displayName } from '../../../config';
import Layout from '../../../components/Layout/Main';

const Confirmation = (): JSX.Element => {
  document.title = `${displayName}: Confirmation`;

  return (
    <Layout>
      <Content />
    </Layout>
  );
};

export default Confirmation;
