import { ActionType, ProColumns } from '@ant-design/pro-table';
import { Typography } from 'antd';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import moment from 'moment-timezone';
import qs from 'qs';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';

import { pageSize } from '../../../config';
import { Action } from '../../../store';
import { moduleName as authModuleName } from '../../../store/ducks/auth';
import { moduleName as charactersModuleName } from '../../../store/ducks/characters';
import { IParams, getRecordsAction, moduleName as recordsModuleName } from '../../../store/ducks/records';
import { RootState } from '../../../store/reducers';
import { ICharacterDetails, IRecord, IUser } from '../../../types/entries';
import { getSorterParams } from '../../../utils';
import AudioWave from '../AudioWave';
import ContentCard from '../ContentCard';
import Pagination from '../Pagination';
import Table from '../Table';
import Tag from '../Tag';

interface ILatestRecordings {
  title?: string;
  characterId?: string;
  character: ICharacterDetails | null;
  user: IUser | null;
  hidePagination?: boolean;
  extra?: React.ReactNode;
  records: IRecord[];
  total: number;
  loading: boolean;
  getRecords: (organisation_uuid: string, params?: IParams, assistant_id?: string) => Action;
}

const LatestRecordings: React.FC<ILatestRecordings> = ({
  title,
  characterId,
  character,
  user,
  hidePagination,
  extra,
  records,
  total,
  loading,
  getRecords,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const actionRef = useRef<ActionType>();
  const [params, setParams] = useState<IParams>({
    page: 1,
    size: pageSize,
    sort: null,
    order: null,
  });

  const handlePageChange = (value: number) => {
    const query = qs.parse(location.search.replace('?', ''));

    navigate({ pathname: location.pathname, search: qs.stringify({ ...query, page: value }) });
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IRecord> | SorterResult<IRecord>[],
  ) => {
    const query = qs.parse(location.search.replace('?', ''));

    const sorterObject = getSorterParams(sorter);

    if (!sorterObject.sort) {
      delete query.sort;
      delete query.order;
    }

    navigate({ pathname: location.pathname, search: qs.stringify({ ...query, ...getSorterParams(sorter) }) });
  };

  useEffect(() => {
    if (!user?.organisation?.uuid) return;

    const query = qs.parse(location.search.replace('?', ''));

    const newParams = {
      size: pageSize,
      page: parseInt(`${query.page || 1}`, 10),
      sort: (query.sort as string) || null,
      order: (query.order as string)?.toLowerCase() || null,
    };

    setParams(newParams);

    getRecords(user.organisation.uuid, newParams, characterId);
  }, [location, user]);

  useEffect(() => {
    if (!actionRef.current) return;

    actionRef.current?.reset?.();
  }, [records]);

  const columns: ProColumns<IRecord>[] = [
    {
      title: 'Date/Time',
      width: 120,
      dataIndex: 'createdAt',
      sorter: true,
      render: (_, row) => (
        <div className="flex-row gap-40">
          <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
            {moment(row.date).format('DD MMM, YYYY')}
          </Typography.Text>
          <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
            {moment(row.date).format('HH:mm')}
          </Typography.Text>
        </div>
      ),
    },
    {
      title: 'Duration',
      dataIndex: 'length',
      width: 100,
      sorter: true,
      render: (_, row) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
          {moment.utc(row.duration * 1000).format('mm:ss')}
        </Typography.Text>
      ),
    },
    {
      title: 'Handler',
      width: 100,
      dataIndex: 'handler',
      renderText: (handler) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">
          {handler.name || character?.name}
        </Typography.Text>
      ),
    },
    {
      title: 'Playback',
      width: 'auto',
      dataIndex: 'audio',
      render: (_, row) => <AudioWave id={row.id} source={row.audio} width="100%" height={40} />,
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      width: 120,
      sorter: true,
      render: (_, row) => <Tag label={row.sentiment} type={row.sentiment} />,
    },
    {
      dataIndex: 'id',
      width: 120,
      render: (_, row) => (
        <Link to={`/characters/${row.handler.id}/records/${row.id}`} className="text-decoration-none fs-12">
          Review Call
        </Link>
      ),
    },
  ];

  return (
    <div className="flex-column gap-16 w-full relative">
      <ContentCard title={title} align="left" extra={extra}>
        <Table<IRecord>
          dataSource={records}
          columns={columns}
          toolBarRender={false}
          search={false}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
        />
      </ContentCard>
      {!hidePagination && total > pageSize && (
        <ContentCard>
          <Pagination page={params.page || 1} onChange={handlePageChange} total={total} pageSize={pageSize} />
        </ContentCard>
      )}
    </div>
  );
};

LatestRecordings.defaultProps = {
  title: undefined,
  characterId: undefined,
  hidePagination: false,
  extra: undefined,
};

const mapStateToProps = (state: RootState) => ({
  records: state[recordsModuleName].records,
  total: state[recordsModuleName].recordsTotal,
  character: state[charactersModuleName].character,
  loading: state[recordsModuleName].loading,
  user: state[authModuleName].user,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRecords: (organisation_uuid: string, params?: IParams, assistant_id?: string) =>
    dispatch(getRecordsAction(organisation_uuid, params, assistant_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LatestRecordings);
