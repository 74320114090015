import { ProColumns } from '@ant-design/pro-table';
import { PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { Button, Checkbox, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../../../../store';
import {
  moduleName as charactersModuleName,
  getVoicesAction,
  updateCharacterAction,
} from '../../../../../../store/ducks/characters';
import { RootState } from '../../../../../../store/reducers';
import { ICharacterDetails, ICharacterUpdate, IVoice } from '../../../../../../types/entries';
import Table from '../../../../../Common/Table';

interface IVoiceTab {
  character: ICharacterDetails | null;
  voices: IVoice[];
  updateCharacter: (body: ICharacterUpdate) => Action;
  getVoices: () => Action;
}

const VoiceTab: React.FC<IVoiceTab> = ({ voices, character, getVoices, updateCharacter }) => {
  const [activeVoiceId, setActiveVoiceId] = useState<string | null>(null);
  const [audioSource, setAudioSource] = useState<string>('');
  const audio = useMemo(() => new Audio(audioSource), [audioSource]);

  useEffect(() => {
    if (!character) return;

    const { voice_id } = character;

    setActiveVoiceId(voice_id);
  }, [character]);

  audio.onended = () => {
    setAudioSource('');
  };

  useEffect(() => {
    if (!audioSource) return;

    audio.play();
  }, [audio]);

  useEffect(() => {
    getVoices();
  }, []);

  const toggleAudio = (event: React.MouseEvent<HTMLElement, MouseEvent>, url: string) => {
    event.stopPropagation();

    audio.pause();
    if (audioSource === url) {
      setAudioSource('');
    } else {
      setAudioSource(url);
    }
  };

  const handleVoiceChange = (event: CheckboxChangeEvent, id: string) => {
    if (!event.target.checked) return;

    setActiveVoiceId(id);
  };

  const columns: ProColumns<IVoice>[] = [
    {
      dataIndex: 'id',
      width: 40,
      renderText: (id) => <Checkbox checked={id === activeVoiceId} onChange={(e) => handleVoiceChange(e, id)} />,
    },
    {
      dataIndex: 'gender',
      renderText: (gender) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">{gender}</Typography.Text>
      ),
    },
    {
      dataIndex: 'age',
      renderText: (age) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">{age}</Typography.Text>
      ),
    },
    {
      dataIndex: 'accent',
      renderText: (accent) => (
        <Typography.Text className="color-primary fs-12 fw-400 ws-nowrap lh-1">{accent}</Typography.Text>
      ),
    },
    {
      width: 40,
      render: (_, row) => {
        const source = `/voices/${row.id}.mp3`;

        return (
          <Button
            type="text"
            className="color-primary"
            icon={audioSource === source ? <PauseRounded /> : <PlayArrowRounded />}
            onClick={(event) => toggleAudio(event, source)}
          />
        );
      },
    },
  ];

  const handleCharacterUpdate = () => {
    if (!activeVoiceId || !character?.uuid) return;

    updateCharacter({ voice_id: activeVoiceId, assistant_uuid: character.uuid });
  };

  return (
    <div className="flex-column gap-16">
      <Table<IVoice>
        dataSource={voices}
        columns={columns}
        toolBarRender={false}
        search={false}
        pagination={false}
        className="table-without-header"
      />
      <div className="flex-row flex-justify-end m-0">
        <Button onClick={handleCharacterUpdate}>Publish changes</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  character: state[charactersModuleName].character,
  voices: state[charactersModuleName].voices,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getVoices: () => dispatch(getVoicesAction()),
  updateCharacter: (body: ICharacterUpdate) => dispatch(updateCharacterAction(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoiceTab);
