import { Typography } from 'antd';
import React from 'react';

import Layout from '../../../components/Layout/Simple';
import { displayName } from '../../../config';

const Placeholder = (): JSX.Element => {
  document.title = `${displayName}: Coming Soon`;

  return (
    <Layout>
      <div
        className="flex-column flex-align-center flex-justify-center gap-4 ph-20 align-center"
        style={{ maxWidth: '35rem' }}
      >
        <Typography.Title level={2} className="fs-18 color-primary m-0">
          Oops! &#128241;
        </Typography.Title>
        <Typography.Title level={2} className="fs-18 color-primary m-0">
          It looks like you’ve caught us on the go!
        </Typography.Title>
        <Typography className="fs-14 color-primary m-0 lh-16">
          Our platform’s not quite ready for mobile yet, but for the best experience, try logging in from a desktop or
          laptop. We’re busy building a mobile-friendly version, so stay tuned, it’s coming soon! &#128640;
        </Typography>
      </div>
    </Layout>
  );
};

export default Placeholder;
