import { Button, Modal } from 'antd';
import React from 'react';

import styles from './index.module.less';

export interface IModalCommon {
  title?: string;
  isVisible: boolean;
  onOk?: () => void;
  onClose: () => void;
  children: React.ReactNode;
  customFooter?: React.ReactNode;
  okButtonText?: string;
  cancelButtonText?: string;
  withCancelButton?: boolean;
  hideFooter?: boolean;
  loading?: boolean;
}

const ModalCommon: React.FC<IModalCommon> = ({
  title,
  isVisible,
  onOk,
  onClose,
  customFooter,
  okButtonText,
  cancelButtonText,
  withCancelButton,
  hideFooter,
  loading,
  children,
}) => (
  <Modal title={title} open={isVisible} onCancel={onClose} footer={null}>
    <div className={styles.modalContent}>
      <div className={styles.modalBody}>{children}</div>
      {!hideFooter &&
        (customFooter || (
          <div className={styles.modalFooter}>
            {withCancelButton && (
              <Button type="default" onClick={onOk}>
                {cancelButtonText || 'Cancel'}
              </Button>
            )}
            <Button type="primary" onClick={onOk} loading={loading}>
              {okButtonText || 'Save'}
            </Button>
          </div>
        ))}
    </div>
  </Modal>
);

ModalCommon.defaultProps = {
  title: undefined,
  onOk: undefined,
  customFooter: undefined,
  okButtonText: undefined,
  cancelButtonText: undefined,
  withCancelButton: false,
  hideFooter: false,
  loading: false,
};

export default ModalCommon;
