import React from 'react';

import DomainSetup from './DomainSetup';
import Script from './Script';

const AccountSettings: React.FC = () => (
  <div className="flex-column gap-24">
    <DomainSetup />
    <Script />
  </div>
);

export default AccountSettings;
