import { Button, Form, Input, Typography } from 'antd';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { password } from '../../../../utils/inputRules';
import styles from '../index.module.less';

interface IResetPasswordData {
  password: string;
}

const ResetPassword: React.FC = () => {
  const { token } = useParams();
  const handleSubmit = (values: IResetPasswordData) => {
    // eslint-disable-next-line
    console.log(values, token);
  };

  return (
    <div className={styles.container}>
      <Typography.Title className={styles.title}>Reset Password</Typography.Title>
      <Form layout="vertical" className={styles.form} onFinish={handleSubmit} labelWrap>
        <Form.Item name="password" label="New Password" rules={password}>
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          name="passwordConfirm"
          label="New Password Confirm"
          dependencies={['password']}
          rules={[
            ...password,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error('The new password that you entered do not mach!'));
              },
            }),
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item noStyle>
          <Button type="primary" size="large" htmlType="submit" className={styles.authButton}>
            Reset Password
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Link className={styles.link} to="/auth/sign-in">
          Return to Sign In
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
