import { call, delay, put, select, takeLatest } from 'redux-saga/effects';

import { alertDelayError, name as appName, getDataDelay, requestCountLimit } from '../../config';
import { JsonResult } from '../../types';
import { IUser } from '../../types/entries';
import { getDataInStorage } from '../../utils/storage';
import { CUSTOMER_ID } from '../constants';
import { Action } from '../index';
import { RootState } from '../reducers';
import { FetchResponse, cancelableLocationSaga, defaultResponseProcessing } from './common';

/**
 * Constants
 * */

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

export const START = `${prefix}/START`;
export const SUCCESS = `${prefix}/SUCCESS`;
export const ERROR = `${prefix}/ERROR`;
export const ERROR_RESET = `${prefix}/ERROR_RESET`;

export const ERROR_401 = `${prefix}/ERROR_401`;
export const ERROR_403 = `${prefix}/ERROR_403`;

export const GET_CURRENT_USER = `${prefix}/GET_CURRENT_USER`;
export const SIGN_IN = `${prefix}/SIGN_IN`;
export const SIGN_IN_BY_TOKEN = `${prefix}/SIGN_IN_BY_TOKEN`;
export const CHECK_IS_USER_PAID = `${prefix}/CHECK_IS_USER_PAID`;
export const SIGN_OUT = `${prefix}/SIGN_OUT`;
export const SIGN_UP = `${prefix}/SIGN_UP`;
export const SEND_VERIFICATION_CODE = `${prefix}/SEND_VERIFICATION_CODE`;
export const VERIFICATION_BY_CODE = `${prefix}/VERIFICATION_BY_CODE`;
export const FINISH_VERIFICATION = `${prefix}/FINISH_VERIFICATION`;
export const VERIFICATION_BY_TOKEN = `${prefix}/VERIFICATION_BY_TOKEN`;
export const FORGOT_PASSWORD = `${prefix}/FORGOT_PASSWORD`;
export const RESET_PASSWORD = `${prefix}/RESET_PASSWORD`;

export const RESET_STATE = `${prefix}/RESET_STATE`;

/**
 * Reducer
 * */
export interface State {
  loading: boolean;
  error: Error | null;
  authorized: boolean;
  user: IUser | null;
  csrfToken: string | null;
  isVerificationInProgress: boolean;
  requestCount: number;
}

export const defaultState: State = {
  loading: false,
  error: null,
  authorized: false,
  user: null,
  csrfToken: null,
  isVerificationInProgress: false,
  requestCount: 0,
};

const localState: State = {
  ...defaultState,
  ...getDataInStorage(moduleName),
};

export default function reducer(state = localState, action: Action = { type: 'undefined' }): State {
  const { type, payload } = action;

  switch (type) {
    case START:
      return { ...state, loading: true, error: null };
    case SUCCESS:
      return { ...state, loading: false, ...payload, requestCount: 0 };
    case ERROR:
      return { ...state, loading: false, error: payload };
    case ERROR_RESET:
      return { ...state, loading: false, error: null };

    case ERROR_403:
      return { ...state, requestCount: state.requestCount + 1 };

    case FINISH_VERIFICATION:
      return { ...state, isVerificationInProgress: false, authorized: true };

    case RESET_STATE:
      return { ...defaultState };

    default:
      return state;
  }
}

/**
 * Interfaces
 * */
export interface ISignIn {
  email: string;
  password: string;
}

export interface ISignUp {
  first_name: string;
  last_name: string;
  email: string;
  organisation_name: string;
  password: string;
}

interface ResponseStatus {
  response: FetchResponse;
  url: string;
  init: RequestInit;
  options: {
    [key: string]: string | boolean;
  };
}

/**
 * Action Creators
 * */
export const getCurrentUserAction = (): Action => ({
  type: GET_CURRENT_USER,
});

export const signIn = ({ email, password }: ISignIn): Action => ({
  type: SIGN_IN,
  payload: { email, password },
});

export const signInByToken = (token: string): Action => ({
  type: SIGN_IN_BY_TOKEN,
  payload: { token },
});

export const checkIsUserPaid = (): Action => ({
  type: CHECK_IS_USER_PAID,
});

export const signOut = (): Action => ({
  type: SIGN_OUT,
});

export const signUpAction = (payload: ISignUp): Action => ({
  type: SIGN_UP,
  payload,
});

export const verifyByCodeAction = (code: string): Action => ({
  type: VERIFICATION_BY_CODE,
  payload: { code },
});

export const sendVerificationCodeAction = (): Action => ({
  type: SEND_VERIFICATION_CODE,
});

export const finishVerificationAction = (): Action => ({
  type: RESET_STATE,
});

export const verifyByTokenAction = (token: string): Action => ({
  type: VERIFICATION_BY_TOKEN,
  payload: { token },
});

export const resetPasswordAction = (password: string): Action => ({
  type: RESET_PASSWORD,
  payload: { password },
});

export const forgotPasswordAction = (email: string): Action => ({
  type: FORGOT_PASSWORD,
  payload: { email },
});

/**
 * Sagas
 */

export function* getCurrentUserSaga(): Generator {
  yield put({
    type: START,
  });
  const response = yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/members/me`);

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (user) => ({
    user,
  }));
}

export function* signInSaga({ payload }: { payload: ISignIn }): Generator {
  yield put({
    type: START,
  });

  const formData = new FormData();

  formData.append('email', payload.email);
  formData.append('password', payload.password);

  const response = yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/login`, {
    method: 'POST',
    body: formData,
  });

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (data) => ({
    user: data.user,
    csrfToken: data.csrf_token,
    authorized: data.csrf_token && data.user.is_verified,
  }));

  const { is_verified } = yield select((state: RootState) => state[moduleName].user);

  if (!is_verified) {
    yield call(sendVerificationCodeSaga);
  }
}

export function* signInByTokenSaga({ payload: { token } }: { payload: { token: string } }): Generator {
  yield put({
    type: START,
  });

  const response = (yield call(
    fetchSaga,
    'https://www.googleapis.com/oauth2/v1/userinfo?alt=json',
    {},
    {
      token,
    },
  )) as FetchResponse;

  const accessToken = process.env.REACT_APP_ACCESS_TOKEN;
  const isUserPaid = true;

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (data) => ({
    accessToken,
    authorized: accessToken && isUserPaid,
    user: {
      id: CUSTOMER_ID,
      email: data.email,
      firstName: data.given_name,
      lastName: data.family_name,
      avatar: data.picture,
      isVerified: true,
      isPasswordExist: true,
    },
  }));
}

export function* signUpSaga({
  payload: { first_name, last_name, email, organisation_name, password },
}: {
  payload: ISignUp;
}): Generator {
  yield put({
    type: START,
  });

  const body = { first_name, last_name, email, organisation_name, password };

  const response = yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/register`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (user) => ({
    user,
  }));

  yield call(sendVerificationCodeSaga);
}

export function* sendVerificationCodeSaga(): Generator {
  const { uuid } = yield select((state: RootState) => state[moduleName].user);

  yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/member/${uuid}/send-verification-email`, {
    method: 'POST',
  });
}

export function* verifyByCodeSaga({ payload: { code } }: { payload: { code: string } }): Generator {
  yield put({
    type: START,
  });

  const user = yield select((state: RootState) => state[moduleName].user);

  const response = yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/verify-code`, {
    method: 'POST',
    body: JSON.stringify({ code }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, () => ({
    isVerificationInProgress: true,
    user: {
      ...user,
      is_verified: true,
    },
  }));
}

export function* verifyByTokenSaga({ payload: { token } }: { payload: { token: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  // eslint-disable-next-line no-console
  console.log(token);
  yield delay(getDataDelay);

  const user = yield select((state: RootState) => state[moduleName].user);

  yield put({
    type: SUCCESS,
    payload: {
      user: {
        ...user,
        isVerified: true,
      },
    },
  });
}

export function* resetPasswordSaga({ payload: { password } }: { payload: { password: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  // eslint-disable-next-line no-console
  console.log(password);
  yield delay(getDataDelay);

  yield put({
    type: SUCCESS,
  });
}

export function* forgotPasswordSaga({ payload: { email } }: { payload: { email: string } }): Generator {
  yield put({
    type: START,
  });

  // TODO add API call
  // eslint-disable-next-line no-console
  console.log(email);
  yield delay(getDataDelay);

  yield put({
    type: SUCCESS,
  });
}

export function* updateStorageSaga(): Generator {
  yield delay(100);

  const data = yield select((state: RootState) => state[moduleName]);

  localStorage.setItem(moduleName, JSON.stringify(data));
}

export function* clearAuthState(): Generator {
  localStorage.clear();
  Object.assign(localState, defaultState);

  yield put({
    type: RESET_STATE,
  });
}

export function* signOutSaga(): Generator {
  yield call(clearAuthState);

  window.location.pathname = '/';
}

export function* refreshTokenSaga(): Generator {
  yield put({
    type: START,
  });

  const response = (yield call(fetchSaga, `${process.env.REACT_APP_AUTH_API_URL}/refresh`, {
    method: 'POST',
  })) as FetchResponse;

  yield defaultResponseProcessing(response, SUCCESS, ERROR, false, (data) => ({ csrfToken: data.csrf_token }));
}

/**
 * @returns Promise<Response>
 *
 * @param props
 */
export function* responseStatus401(props: ResponseStatus): Generator {
  const { response, url, init = {}, options = {} } = props;

  function* tryAgain(): Generator {
    const { authorized, error } = (yield select((state: RootState) => state[moduleName])) as State;

    if (authorized && error === null) {
      return yield call(fetchSaga, url, init, options);
    }

    return response;
  }

  yield put({
    type: ERROR_401,
  });

  const type = response.headers.get('Content-Type');

  if (!response.data && (type === null || (type && type.indexOf('/json') !== -1))) {
    response.data = (yield response.json()) as JsonResult;
  }

  if (response.data?.detail === 'The expiration claim is invalid') {
    yield refreshTokenSaga();

    return yield tryAgain();
  }

  if (
    options.signOut !== false &&
    response.data?.error?.message === 'Missing Authorization Header' &&
    response.data?.error?.type === 'Auth'
  ) {
    yield signOutSaga();
  }

  return response;
}

/**
 * @returns Promise<Response>
 *
 * @param props
 */
export function* responseStatus403(props: ResponseStatus): Generator {
  const { url, init = {}, options = {} } = props;

  yield put({
    type: ERROR_403,
  });

  const { requestCount } = yield select((state: RootState) => state[moduleName]);

  if (requestCount < requestCountLimit) {
    yield getCurrentUserSaga();

    return yield call(fetchSaga, url, init, options);
  }

  return yield signOutSaga();
}

/**
 * @param {String} url
 * @param {Object} init
 * @param {Object} options
 *
 * @returns {IterableIterator<Promise<Response>*>}
 */
export function* fetchAuthSaga(
  url: string,
  init: RequestInit = {},
  options: { [key: string]: boolean | string } = {},
): Generator {
  const newInit = {
    credentials: 'include',
    ...init,
  };

  newInit.headers = {
    Accept: 'application/json',
    ...(newInit.headers || {}),
  };

  if (options?.token && options?.authorization !== false) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newInit.headers.Authorisation = `Bearer ${options?.token || accessToken}`;
  }

  const { csrfToken } = (yield select((state: RootState) => state[moduleName])) as State;

  if (csrfToken && options?.authorization !== false) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newInit.headers['X-CSRF-Token'] = csrfToken;
  }

  return yield fetch(url, newInit as RequestInit);
}

/**
 * @param {String} url
 * @param {Object} init
 * @param {Object} options
 *
 * @returns Promise<Response>
 */
export function* fetchSaga(
  url: string,
  init: RequestInit = {},
  options: { [key: string]: string | boolean } = {},
): Generator {
  if (process.env.REACT_APP_FETCH_DELAY) {
    yield delay(parseInt(process.env.REACT_APP_FETCH_DELAY, 10));
  }

  try {
    const response = (yield call(fetchAuthSaga, url, init, options)) as FetchResponse;

    switch (response.status) {
      case 401:
        return yield responseStatus401({
          response,
          url,
          init,
          options,
        });
      case 403:
        return yield responseStatus403({
          response,
          url,
          init,
          options,
        });
      default:
        return response;
    }
  } catch (err) {
    return err;
  }
}

export function* errorResetSaga(): Generator {
  yield delay(alertDelayError);
  yield put({
    type: ERROR_RESET,
  });
}

export function* saga(): Generator {
  yield takeLatest(GET_CURRENT_USER, cancelableLocationSaga.bind(null, getCurrentUserSaga, ERROR, false));
  yield takeLatest(SIGN_IN, cancelableLocationSaga.bind(null, signInSaga, ERROR, false));
  yield takeLatest(SIGN_IN_BY_TOKEN, cancelableLocationSaga.bind(null, signInByTokenSaga, ERROR, false));
  yield takeLatest(SIGN_UP, cancelableLocationSaga.bind(null, signUpSaga, ERROR, false));
  yield takeLatest(SEND_VERIFICATION_CODE, cancelableLocationSaga.bind(null, sendVerificationCodeSaga, ERROR, false));
  yield takeLatest(VERIFICATION_BY_CODE, cancelableLocationSaga.bind(null, verifyByCodeSaga, ERROR, false));
  yield takeLatest(VERIFICATION_BY_TOKEN, cancelableLocationSaga.bind(null, verifyByTokenSaga, ERROR, false));
  yield takeLatest(FORGOT_PASSWORD, cancelableLocationSaga.bind(null, forgotPasswordSaga, ERROR, false));
  yield takeLatest(RESET_PASSWORD, cancelableLocationSaga.bind(null, resetPasswordSaga, ERROR, false));
  yield takeLatest(SIGN_OUT, cancelableLocationSaga.bind(null, signOutSaga, ERROR, false));
  yield takeLatest(ERROR, errorResetSaga);

  yield takeLatest([SUCCESS, RESET_STATE, FINISH_VERIFICATION, ERROR_401], updateStorageSaga);
}
