import { UserOutlined } from '@ant-design/icons';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { Avatar, Button, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import {
  moduleName as authModuleName,
  getCurrentUserAction,
  signOut as signOutAction,
} from '../../../store/ducks/auth';
import { RootState } from '../../../store/reducers';
import { IUser } from '../../../types/entries';
import Simple from '../Simple';
import MenuCommon from './Menu';
import styles from './index.module.less';

interface IMain {
  children: React.ReactNode;
  title?: string;
  extra?: React.ReactNode;
  withBackButton?: boolean;
  user: IUser | null;
  authorized: boolean;
  signOut: () => Action;
  getCurrentUser: () => Action;
}

const Main: React.FC<IMain> = ({
  children,
  title,
  extra,
  withBackButton,
  user,
  authorized,
  signOut,
  getCurrentUser,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Simple>
      {authorized && <MenuCommon />}
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className="flex-row gap-10 flex-align-center flex-justify-start">
              {title && (
                <>
                  {withBackButton && (
                    <Button
                      type="text"
                      icon={<ChevronLeftOutlined className="fs-40 color-primary" />}
                      onClick={goBack}
                    />
                  )}
                  <Typography.Title className={styles.title}>{title}</Typography.Title>
                </>
              )}
            </div>

            <div className="flex-row gap-12 flex-align-center flex-justify-start">
              {extra}
              <div className="flex-column gap-4">
                <Typography.Text
                  className={styles.username}
                >{`${user?.first_name} ${user?.last_name}`}</Typography.Text>
                <Button type="text" className={styles.logout} onClick={signOut}>
                  Log Out
                </Button>
              </div>
              <Avatar size={60} icon={<UserOutlined />} src={user?.avatar} />
            </div>
          </div>
          <div className={styles.contentWrapper}>{children}</div>
        </div>
      </div>
    </Simple>
  );
};

Main.defaultProps = {
  title: undefined,
  extra: undefined,
  withBackButton: false,
};

const mapStateToProps = (state: RootState) => ({
  user: state[authModuleName].user,
  authorized: state[authModuleName].authorized,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signOut: () => dispatch(signOutAction()),
  getCurrentUser: () => dispatch(getCurrentUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
