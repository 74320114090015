import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { Action } from '../../../store';
import { moduleName as customerModuleName, getPlans as getPlansAction } from '../../../store/ducks/customer';
import { RootState } from '../../../store/reducers';
import { IPlan } from '../../../types';
import Loading from '../../Common/Loading';
import PlanCard from './PlanCard';
import styles from './index.module.less';

interface IPlans {
  plans: IPlan[] | null;
  loading: boolean;
  getPlans: () => Action;
}

const Plans = ({ plans, loading, getPlans }: IPlans) => {
  useEffect(() => {
    getPlans();
  }, []);

  return (
    <>
      <Loading
        visible={!!loading}
        fixed
        className="z-index-100 background-transparent backdrop-filter-blur-10 border-radius-16"
      />
      <div className={styles.cardsWrapper}>{plans?.map((plan) => <PlanCard key={plan.id} plan={plan} />)}</div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  plans: state[customerModuleName].plans,
  loading: state[customerModuleName].loading,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getPlans: () => dispatch(getPlansAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
