import clsx from 'clsx';
import React from 'react';

import styles from './index.module.less';

interface Loading {
  size?: 'small' | 'medium' | 'large';
  fixed?: boolean;
  absolute?: boolean;
  visible: boolean;
  className?: string;
}

const Loading = ({ size, absolute, fixed, visible, className }: Loading): JSX.Element | null =>
  visible ? (
    <div
      className={clsx(styles.loading, className, {
        [styles.absolute]: absolute,
        [styles.fixed]: fixed,
      })}
    >
      <span className={clsx(styles.loader, styles[size || 'large'])} />
    </div>
  ) : null;

Loading.defaultProps = {
  size: 'large',
  fixed: false,
  absolute: false,
  className: undefined,
  background: 'rgba(255, 255, 255, 0.55)',
  backdropFilter: 'none',
};

export default Loading;
