import { Button, Form, Input, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useState } from 'react';

import UploadModal from '../UploadModal';
import styles from '../index.module.less';

interface ITextUpload {
  name: string;
}
const LinkUpload: React.FC<ITextUpload> = ({ name }) => {
  const [form] = useForm();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleModalOpen = () => {
    setIsVisible(true);
  };

  const handleModalClose = () => {
    setIsVisible(false);
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      // eslint-disable-next-line no-console
      console.log(values);

      handleModalClose();
    });
  };

  return (
    <>
      <UploadModal
        title={`Upload information for ${name}`}
        isVisible={isVisible}
        onOk={handleModalOk}
        onClose={handleModalClose}
      >
        <Form form={form}>
          <div className="flex-column gap-10">
            <div className="flex-column gap-5">
              <Typography className="fs-16 lh-1 color-primary fw-500">Scrape your website</Typography>
              <Typography className="fs-16 lh-1 color-primary fw-400">
                Provide us with a URL to gather information from
              </Typography>
            </div>
            <Form.Item name="link">
              <Input className={styles.input} size="large" placeholder="https://www.yourwebsite.com/" />
            </Form.Item>
          </div>
        </Form>
      </UploadModal>
      <Button type="default" onClick={handleModalOpen}>
        Upload text
      </Button>
    </>
  );
};

export default LinkUpload;
