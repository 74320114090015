import { Button, Form, Input, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Action, Dispatch } from 'redux';

import { alertDelayError } from '../../../../config';
import { useContextNotifications } from '../../../../context/notifications';
import { ISignIn, moduleName as authModuleName, signIn as signInAction } from '../../../../store/ducks/auth';
import { RootState } from '../../../../store/reducers';
import { login } from '../../../../utils/inputRules';
import { Logo } from '../../../Common/Icon';
import styles from '../index.module.less';

interface ISignInComponent {
  loading: boolean;
  error: Error | null;
  signIn: (data: ISignIn) => Action;
}
const SignIn: React.FC<ISignInComponent> = ({ loading, error, signIn }) => {
  const { openNotification } = useContextNotifications();

  useEffect(() => {
    if (!error) return;

    openNotification?.({ message: error.message, type: 'error', duration: alertDelayError / 1000 });
  }, [error]);

  // TODO Commented for current iteration
  // const handleGoogleSuccess = (credentialResponse: CredentialResponse) => {
  //   // eslint-disable-next-line
  //   console.log(2, credentialResponse);
  // };
  //
  // useGoogleOneTapLogin({
  //   onSuccess: handleGoogleSuccess,
  //   auto_select: true,
  // });

  // TODO Commented for current iteration
  // const handleSignIn = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     if (tokenResponse.hd === 'neuralriver.ai' || tokenResponse.hd === 'neural-voice.ai') {
  //       signInByToken(tokenResponse.access_token);
  //     }
  //   },
  // });

  // TODO Commented for current iteration
  // const handleGoogleSignIn = () => {
  //   handleSignIn();
  // };

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <Typography.Title className={styles.title}>Log in to Neural Voice</Typography.Title>
      {/* TODO Commented for current iteration */}
      {/* <Button size="large" onClick={handleGoogleSignIn} className={styles.authButton} icon={<IconGoogle />}> */}
      {/*  Log in with Google */}
      {/* </Button> */}
      {/* <div className={styles.divider}> */}
      {/*  <div className={styles.line} /> */}
      {/*  <Typography className={styles.text}>or Log In with email</Typography> */}
      {/*  <div className={styles.line} /> */}
      {/* </div> */}
      <Form layout="vertical" className={styles.form} onFinish={signIn} labelWrap>
        <Form.Item name="email" label="Email" rules={login} validateStatus={error ? 'error' : 'success'}>
          <Input size="large" />
        </Form.Item>
        <Form.Item className={styles.passwordContainer}>
          <Form.Item name="password" label="Password" validateStatus={error ? 'error' : 'success'}>
            <Input.Password size="large" />
          </Form.Item>
          <Link className={styles.forgotPassword} to="/auth/forgot-password">
            Forgot?
          </Link>
        </Form.Item>
        <Form.Item noStyle>
          <Button size="large" type="primary" htmlType="submit" className={styles.authButton} loading={loading}>
            Log In
          </Button>
        </Form.Item>
      </Form>
      <div className={styles.footer}>
        <Typography className={styles.link}>
          Don't have an account?{' '}
          <Link className={styles.link} to="/auth/sign-up">
            Sign Up
          </Link>
        </Typography>
        <div className={styles.backLink}>
          <Link className={styles.link} target="_blank" to={process.env.REACT_APP_NEURAL_VOICE_URL || ''}>
            Return to Neural Voice
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: state[authModuleName].loading,
  error: state[authModuleName].error,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  signIn: (data: ISignIn) => dispatch(signInAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
