import { PauseRounded, PlayArrowRounded } from '@mui/icons-material';
import { Button } from 'antd';
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Action } from '../../../store';
import { moduleName as localModuleName, updatePlayId as updatePlayIdAction } from '../../../store/ducks/local';
import { RootState } from '../../../store/reducers';
import styles from './index.module.less';

interface IAudioWave {
  id: string;
  source: string;
  width?: number | string;
  height?: number | string;
  playId: string | null;
  updatePlayId: (value: string | null) => Action;
}

const AudioWave: React.FC<IAudioWave> = ({ id, source, width, height, playId, updatePlayId }) => {
  const location = useLocation();
  const currentTimeRef = useRef<number>(0);
  const intervalRef = useRef<NodeJS.Timeout>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const progressWaveRef = useRef<HTMLCanvasElement | null>(null);
  const eventsRef = useRef<string[]>([]);
  const audio = useMemo(() => new Audio(source), [source]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  useEffect(
    () => () => {
      pauseAudio();
    },
    [],
  );

  useEffect(() => {
    pauseAudio();
  }, [location]);

  const pauseAudio = () => {
    updatePlayId(null);

    setIsPlaying(false);
    audio.pause();
    if (!intervalRef.current) return;

    clearInterval(intervalRef.current);
  };

  const playAudio = (time?: number) => {
    updatePlayId(id);

    if (time) {
      currentTimeRef.current = time;
    }

    setIsPlaying(true);
    audio.play();

    intervalRef.current = setInterval(() => {
      currentTimeRef.current += 100;
      if (!progressRef.current) return;

      progressRef.current.style.width = `${(currentTimeRef.current * 100) / (audio.duration * 1000)}%`;
      if (!Number(progressRef.current.style.opacity)) {
        progressRef.current.style.opacity = '1';
      }

      if (currentTimeRef.current >= audio.duration * 1000) {
        pauseAudio();
        currentTimeRef.current = 0;
        progressRef.current.style.opacity = '0';
      }
    }, 100);
  };

  useEffect(() => {
    if (isPlaying && playId && playId !== id) {
      pauseAudio();
    }
  }, [playId, id, isPlaying]);

  const handleRewind = (event: MouseEvent<HTMLDivElement>) => {
    if (!containerRef.current || !progressRef.current) return;

    const x = event.clientX - containerRef.current.getBoundingClientRect().left;
    const percent = (x * 100) / containerRef.current.offsetWidth;

    progressRef.current.style.width = `${percent}%`;

    if (!progressRef.current.style.opacity) {
      progressRef.current.style.opacity = '1';
    }

    const newTime = (audio.duration * 1000 * percent) / 100;

    audio.currentTime = (audio.duration * percent) / 100;
    currentTimeRef.current = newTime;
  };

  const handleMouseDown = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    eventsRef.current = [...eventsRef.current, 'mousedown'];
    if (audio.paused) {
      eventsRef.current = [...eventsRef.current].filter((event) => event !== 'play');
    } else {
      eventsRef.current = [...eventsRef.current, 'play'];
    }
    handleRewind(mouseEvent);
    pauseAudio();
  };

  const handleMouseMove = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    if (eventsRef.current.includes('mousedown')) {
      handleRewind(mouseEvent);
    }
  };

  const handleMouseUp = () => {
    eventsRef.current = [...eventsRef.current].filter((event) => event !== 'mousedown');

    if (eventsRef.current.includes('play')) {
      playAudio();
    }
  };

  const toggleAudio = () => (isPlaying ? pauseAudio() : playAudio());

  return (
    <div className={styles.container} style={{ width, height }} onMouseMove={handleMouseMove}>
      <Button id="playButton" type="text" className={styles.playButton} onClick={toggleAudio}>
        {isPlaying ? <PauseRounded /> : <PlayArrowRounded />}
      </Button>
      <div ref={containerRef} className={styles.waveContainer} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
        <div ref={progressRef} className={styles.progress}>
          <div style={{ width: containerRef.current?.offsetWidth }} className={styles.waveWrapper}>
            <canvas ref={progressWaveRef} className={styles.wave} />
          </div>
        </div>
      </div>
    </div>
  );
};

AudioWave.defaultProps = {
  width: '100%',
  height: '100%',
};

const mapStateToProps = (state: RootState) => ({
  playId: state[localModuleName].playId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updatePlayId: (value: string | null) => dispatch(updatePlayIdAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AudioWave);
