import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Route as PublicRoute, Routes } from 'react-router-dom';

import AccountSettings from '../pages/accountSettings';
import ForgotPassword from '../pages/auth/forgotPassword';
import ResetPassword from '../pages/auth/resetPassword';
import SignIn from '../pages/auth/signIn';
import SignUp from '../pages/auth/signUp';
import VerificationSuccess from '../pages/auth/verificationSuccess';
import VerifyEmail from '../pages/auth/verifyEmail';
import NotFound from '../pages/errors/notFound';
import { moduleName as authModuleName } from '../store/ducks/auth';
import { RootState } from '../store/reducers';
import { IRoute } from '../types';
import { IUser, UserRoleEnum } from '../types/entries';
import sideMenuRoutes from './sideMenu';

export const createChildrenRoutes = (initialRoutes: IRoute[]): IRoute[] => {
  const list: IRoute[] = [...initialRoutes];

  function addChildren(route: IRoute, parent?: IRoute): void {
    let newRoute = route;

    if (parent) {
      newRoute = {
        ...route,
        parent,
        bind: {
          ...route.bind,
          path: `${parent.bind.path}${route.bind.path}`,
        },
      };
      list.push(newRoute);
    }

    if (newRoute.children) {
      newRoute.children.forEach((child) => addChildren(child, newRoute));
    }
  }

  list.forEach((route) => addChildren(route, undefined));

  return list;
};

interface IRouteComponent {
  authorized: boolean;
  user: IUser | null;
}
const Route: React.FC<IRouteComponent> = ({ authorized, user }): JSX.Element => {
  const filteredRoutes = sideMenuRoutes.filter((route) => !!route.adminRoute === (user?.role === UserRoleEnum.admin));

  const routes: IRoute[] = createChildrenRoutes([
    {
      bind: {
        path: '/auth',
      },
      name: 'Auth',
      children: [
        {
          bind: {
            path: '/sign-in',
            element: <SignIn />,
          },
          name: 'Sign In',
        },
        {
          bind: {
            path: '/sign-up',
            element: <SignUp />,
          },
          name: 'Sign Up',
        },
        {
          bind: {
            path: '/verify-email',
            element: <VerifyEmail />,
          },
          name: 'Verify Email',
        },
        {
          bind: {
            path: '/verification/success',
            element: <VerificationSuccess />,
          },
          name: 'Verification Success',
        },
        {
          bind: {
            path: '/forgot-password',
            element: <ForgotPassword />,
          },
          name: 'Forgot Password',
        },
        {
          bind: {
            path: '/reset-password/:token',
            element: <ResetPassword />,
          },
          name: 'Reset Password',
        },
      ],
    },
    {
      bind: {
        path: '/settings',
        element: <AccountSettings />,
      },
      name: 'Settings',
      privateRoute: true,
    },
    {
      bind: {
        path: '/not-found',
        element: <NotFound />,
      },
      name: 'Not Found',
    },
    ...filteredRoutes,
  ]);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => (
          <PublicRoute
            key={route.bind.path}
            {...route.bind}
            element={
              !route.privateRoute || (route.privateRoute && authorized) ? (
                route.bind.element
              ) : (
                <Navigate to="/auth/sign-in" replace />
              )
            }
          />
        ))}
        <PublicRoute path="/" element={<Navigate to="/home" replace />} />
        <PublicRoute path="/health" element={<h3>Hey There!!! The App is Healthy</h3>} />
        <PublicRoute path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state: RootState) => ({
  authorized: state[authModuleName].authorized,
  user: state[authModuleName].user,
});

export default connect(mapStateToProps)(Route);
